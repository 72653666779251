.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 15em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 50%;
    min-width: 350px;
    max-width: 550px;
    min-height: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animate-opacity;
    -webkit-animation-duration: 0.4s;
    animation-name: animate-opacity;
    animation-duration: 0.4s
}

@-webkit-keyframes animate-opacity {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes animate-opacity  {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding: 10px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background-color: #9fa09f;
    color: white;
    z-index: 100;
    margin: 1px;
    margin-bottom: 2px;
}

.modal-header-icon {
    margin-top: 5px;
    margin-left: -7px;
}

.modal-header-title {
    color: white;
    margin-top: 3px;
    padding-left: 7px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
}

.modal-body {
    padding: 10px 16px;
}